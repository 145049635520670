@use 'sass:math';

@use 'node_modules/@remodzy/theme/src/theme';

@use 'app/styles/mixins';
@use 'app/styles/variables';

html {
  height: 100vh;
  font-size: 14px;
}

body {
  height: 100%;
  overflow-x: hidden;
  position: relative;
}

label {
  .inline {
    margin-top: 2rem !important;
  }

  & > input,
  & > textarea,
  & > ng-select {
    margin-top: 0.5rem;
  }
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.load-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ngb-highlight {
  font-weight: variables.$font-weight-normal;
}

.main-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.main-content-header {
  padding: 1rem 1.5rem 0 1.5rem;
  flex: 0 0 auto;
  display: flex;
  align-content: center;
  justify-content: space-between;
  align-items: center;

  .main-content-header-ls {
    display: flex;
    align-items: center;
    flex: 1;
    padding: 1rem 0;
  }

  .main-content-header-mid {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;

    .date-period {
      font-weight: variables.$font-weight-semibold;
    }
  }

  .main-content-header-rs {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    padding: 1rem 0;
  }

  .date-period {
    background-color: variables.$sidenav-color;
    border-radius: 43px;
    display: flex;
    margin-left: 1rem;
    padding: 1.2rem;
    justify-content: space-between;
    min-width: 300px;
  }
}

.main-content-body-fluid {
  flex: 1 1 auto;
  padding: 0 1.5rem 1.5rem 1.5rem;
}

.main-content-body {
  flex: 1 1 auto;
  padding: 0 1.5rem 1.5rem 1.5rem;
  max-width: 970px;
}

.side-nav {
  background-color: variables.$sidenav-color;
  flex: 0 0 300px;
}

.side_menu {
  display: flex;
  flex-direction: column;
  align-content: center;
  padding: variables.$nav-padding;

  .side-menu-link {
    color: variables.$color-text-light;
    margin-bottom: variables.$nav-padding;
  }

  .side-menu-link:hover {
    color: variables.$color-primary;
  }

  .side-menu-link.side-menu-active {
    color: variables.$color-primary;
    font-weight: variables.$font-weight-semibold;
  }
}

.divider {
  border-bottom: 1px solid variables.$color-input-border;
}

.modal-divider {
  border-bottom: 1px solid variables.$color-input-border;
  box-sizing: border-box;
  margin-right: -20px;
  margin-left: -220px;
}

.nav-tabs {
  padding: 1rem 0;
  border-bottom: 1px solid variables.$color-input-border;
  font-weight: variables.$font-weight-semibold;

  .nav-link.nav-item {
    padding: 1rem 0;
    color: variables.$color-text-light;
    margin-left: 3rem;
  }

  .nav-link.nav-item:first-child {
    padding-left: 0;
    margin-left: 0;
  }

  .nav-link.nav-item.active {
    color: variables.$color-primary;
    border-bottom: 2px solid variables.$color-primary;
  }

  &_vertical {
    display: flex;
    flex-direction: column;
    border: none;

    .nav-link.nav-item {
      padding-left: 0;
      margin-left: 0;
    }

    .nav-link.nav-item.active {
      border: none;
    }
  }
}

h4,
h5,
h6 {
  font-weight: variables.$font-weight-semibold;
}

.btn-link {
  padding: 0;
}

.footer-between {
  padding-top: 1.5rem;
  display: flex;
  justify-content: space-between;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: variables.$color-lightgray;
  border-color: variables.$color-lightgray;
}

.b-menu-content {
  padding: 1rem;
  color: variables.$color-primary;
  font-weight: variables.$font-weight-semibold;
  cursor: pointer;
}

:focus {
  outline: none;
}

.b-menu-content {
  background: white;
}

.round_icon {
  margin-left: 1rem;
  cursor: pointer;
  background-color: variables.$sidenav-color;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-items: center;
}

.round_icon.close {
  background-color: variables.$color-primary;
}

.btn-lg {
  min-width: 9rem;
}

.profile-logo {
  letter-spacing: 1px;
  width: 3.2rem;
  height: 3.2rem;
  background-color: variables.$color-primary;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  color: white;
}

.pre-load-modal {
  position: absolute;
  background-color: white;
  opacity: 0.9;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.b-resource-role {
  width: 140px;
  white-space: pre-wrap;
}

.ngb-tp-input-container {
  width: 5em !important;
}

.c-primary {
  color: variables.$color-primary !important;
}

.btn-icon {
  @include mixins.btn-icon-base();

  img,
  svg {
    width: 24px;
    height: 24px;
  }
}

.btn-icon-sm {
  @include mixins.btn-icon-base();

  img,
  svg {
    width: 16px;
    height: 16px;
  }
}

.btn-icon-no-bg {
  @include mixins.btn-icon-no-bg-base();

  svg {
    width: 24px;
    height: 24px;
  }
}

.btn-icon-no-bg-sm {
  @include mixins.btn-icon-no-bg-base();

  svg {
    width: 16px;
    height: 16px;
  }
}

.btn-icon-no-bg-md {
  @include mixins.btn-icon-no-bg-base();

  svg {
    width: 1rem;
    height: 1rem;
  }
}

.count-badge {
  color: variables.$color-text-light;
  padding-left: 1rem;
  padding-right: 1rem;
  border: 1px solid variables.$color-input-border;
  border-radius: 1rem;
  margin-left: 1rem;
  line-height: 2rem;
}

.profile-menu {
  display: flex;
  flex-direction: column;
}

button.main-menu-link {
  text-align: left;
  padding: 0.5rem;
  border: none;
}

.main-menu-link {
  color: variables.$color-text-light;
  padding: 0.5rem;

  &.active {
    color: variables.$color-primary;
    font-weight: variables.$font-weight-semibold;
  }

  &:hover {
    color: variables.$color-primary;
  }
}

.ar-4_3 {
  @include mixins.aspect-ratio-block(math.div(3, 4));
}

.ar-16_9 {
  @include mixins.aspect-ratio-block(math.div(9, 16));
}

// Algolia list
.algolia-pending,
.algolia-deleting {
  background: repeating-linear-gradient(
    -45deg,
    variables.$color-background,
    variables.$color-background 50px,
    transparent 50px,
    transparent 85px
  );
  animation: pendingAnimation 3s linear infinite;
  background-size: 100vw 100vh;
}

.algolia-deleting {
  pointer-events: none;
}

@keyframes pendingAnimation {
  0% {
    background-position: -120px 0;
  }

  100% {
    background-position: 0 0;
  }
}

.spinner-border {
  width: 1.5rem;
  height: 1.5rem;
}

.modal-body > form > .row:not(:last-of-type),
.modal-body > .row:not(:last-of-type) {
  margin-bottom: 1rem;
}

.full-screen-modal {
  .modal-dialog {
    width: 100%;
    max-width: none;
    height: 100%;
    margin: 0;

    .modal-content {
      height: 100%;
      border: 0;
      border-radius: 0;
      background-color: variables.$color-text-dark;
    }
  }
}

.modal-backdrop.fade {
  transition: none;
}

.temporally-hidden {
  display: none !important;
}
