@forward 'node_modules/@remodzy/theme/src/functions';

@use 'variables';

@mixin aspect-ratio-block($ar) {
  width: 100%;
  padding-bottom: $ar * 100%;
  position: relative;

  & > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

@mixin vertical-ellipsis($rows) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $rows;
  overflow: hidden;
  word-break: break-all;
}

@mixin ellipsis($maxWidth: 100%) {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: $maxWidth;
}

@mixin btn-icon-base {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 52px;
  height: 52px;

  &:not(.btn-primary) {
    color: rgba(variables.$color-text-dark, 0.72);
    background-color: variables.$color-input-bg;
  }
}

@mixin btn-icon-no-bg-base {
  @include btn-icon-base;

  width: 24px;
  height: 24px;
  padding: 0;
  background-color: transparent !important;
  border: none;
}
